@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
body{
  font-size: 13px!important;
  font-family: 'Montserrat', sans-serif!important;
  color: #454545;
}
.logo{
  font-size:33px;
  font-family: 'Anton', sans-serif;
  line-height: 1;
  letter-spacing: 11px;
}
.logo p{
  font-size: 13px;
  font-family: 'Montserrat', sans-serif!important;
  letter-spacing: normal;
}
.def-pad{
  padding:20px;
  border:solid 1px red;
}
.tp-heading{
  display: flex;
  align-items: center;
  box-shadow:0 2px 3px rgba(0,0,0,.2) ;
  padding: 5px 10px;
}
button:focus{
  outline: none;
  box-shadow: none;
}
.header{
  width: 80%;
  padding: 15px 10px 10px;
  background-color: #fff;
  display: inline-block;
  box-sizing: border-box;
}
.header .left {
  display: inline-block;
  float: left;
}
.header .left span{
  font-size: 24px;
  color: #222;
}
.header .right{
  float: right;
}
.header .right .button-lock{
  float: right;
  margin-left: 10px;
  padding: 8px 12px;
  background-color: #43ac6a;
  color: #fff;
  border: solid 1px #43ac6a;
  cursor: pointer;
  font-size: 14px;
}
.header .right .button-lock:hover{
  background-color: #358753;
}
.header .right .dropdown{
  display: inline-block;
  float: right;
}
.header .right .dropdown button{
  padding: 8px 12px;
  background-color: #fff;
  color: #008cba;
  border: solid 1px #fff;
  cursor: pointer;
  position: relative;
  font-size: 14px;
}
.header .right .dropdown button:hover{
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.header .right .dropdown button:focus{
  box-shadow: none;
}
.header .right .dropdown ul{
  border: solid 1px #d3d3d3;
  list-style: none;
  margin: 0px;
  padding: 0px;
  box-shadow: 0 6px 12px rgba(0,0,0,.15);
  position: absolute;
  z-index: 1;
  background-color: #fff;
  min-width: 150px;
}
.header .right .dropdown ul li a{
  padding: 12px;
  color: #555;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.header .right .dropdown ul li a:hover{
  background-color: #eee;
}
.tab-content-sec{
  padding: 20px 0px;
  background-color: #EBEEF0;
  height: 90vh;
  width: 80%;
  float: right;
}
.tab-content-sec ul.nav-tabs{
  background-color: #f5f5f5;
  border-bottom: none;
}
.tab-content-sec ul.nav-tabs li a{
  border-radius: inherit;
  border: none;
  background-color: #f5f5f5;
  border: solid 1px transparent;
  border-right-color: silver;
  padding: 12px 20px;
  color: #222;
}
.tab-content-sec ul.nav-tabs li a span{
  width: 23px;
  height: 20px;
  border-radius: 20px;
  background-color: #d3d3d3;
  font-size: 12px;
  color: #222;
  display: inline-block;
  text-align: center;
  line-height: 20px;
}
.tab-content-sec ul.nav-tabs li a:hover{
  background-color: #eee;
  border-bottom: solid 1px #f5f5f5;
  border-right-color: silver;
}
.tab-content-sec ul.nav-tabs li a.active{
  background-color: #fff;
  color: #222;
}
.tab-content-sec ul.nav-tabs li a.active:hover{
  background-color: #fff;
  color: #222;
  border-color: #fff;
  border-right-color: silver;
}
.tab-content-sec .tab-content{
  background-color: #fff;
  overflow: hidden;
}
.tab-content-sec .tab-content .tab-pane{
  padding: 20px;
  overflow: hidden;
}
.tab-content .active .tab-pane {
  display: block;
}

.active .fade:not(.show) {
  opacity: 1;
}
/* #general-current .active .tab-pane {
  display: none;
} */
/* #general-current .active .fade:not(.show) {
  opacity: 0;
} */
#Orders .search-order, #current .search-items{
  width: 100%;
  height: 41px;
  padding: 8px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  margin-bottom: 15px;
}
#Orders .search-order:focus, #current .search-items:focus{
  outline: none;
  border-color: #66afe9;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}
#Orders ul.nav-tabs{
  width: 200px;
  float: left;
}
#Orders ul.nav-tabs li{
  width: 100%;
}
#Orders ul.nav-tabs li a{
  background-color: #fff;
  display: block;
  border: solid 1px #ebeef0;
}
#Orders ul.nav-tabs li a.active,#Orders ul.nav-tabs li a:hover{
  background-color: #f5f5f5;
}
#Orders .tab-content{
  width: calc(100% - 200px);
}
#Orders .tab-content .tab-pane{
  padding: 0px 20px;
}
.tab-content .tab-pane .box{
  height: 140px;
  width: 15%;
  margin-bottom: 20px;
  float: left;
  margin: 0px 0.83% 5px;
}
.tab-content .tab-pane .order-box{
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5f5;
  border:solid 1px #f5f5f5;
  border-bottom: solid 3px silver;
  cursor: pointer;
}
.tab-content .tab-pane .box .split{
  text-align: center;
  font-size: 13px;
  color: #008cba;
}
.tab-content .tab-pane .box .split.hide{
  visibility: hidden;
}
.tab-content .tab-pane .order-box.active{
  border-bottom-color: #58d68d;
}
.tab-content .tab-pane .order-box:hover{
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
}
.tab-content .tab-pane .order-box p{
  margin-bottom: 5px;
}
.tab-content .tab-pane .order-box p.hide{
  display: none;
}
.tab-content .tab-pane .order-box span{
  display: none;
  font-size: 12px;
}
.tab-content .tab-pane .order-box span.show{
  display: block;
}
.tab-content .tab-pane .order-box span .circle{
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #58d68d;
  display: inline-block;
  float: left;
  margin-right: 10px;
  margin-top: 5px;
}

#current .left{
  width: 60%;
  float: left;
  padding-right: 15px;
  border-right:solid 1px #ccc;
}
#current .left ul.nav-tabs{
  width: 20%;
  float: left;
}
#current .left ul.nav-tabs li{
  width: 100%;
}
#current .left ul.nav-tabs li a{
  background-color: #fff;
  display: block;
  border: solid 1px #ebeef0;

}
#current .left ul.nav-tabs li a.active,#Current .left ul.nav-tabs li a:hover{
  background-color: #f5f5f5;
  /* pointer-events: none; */
}
#current .disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
#current .left .tab-content{
  width: 80%;
  float: right;
}
#current .tab-content .tab-pane{
  padding: 0px 20px;
}
#current .tab-content .tab-pane .box{
  width: 23%;
  margin: 0 1% 20px;
  height: 100px;
}
#current .tab-content .tab-pane .box .order-box{
  border-bottom: solid 3px #58d68d;
  height: 90px;
}
#current .tab-content .tab-pane .box .order-box p.prize{
  font-size: 13px;
  color: #999;
}
#current .right{
  width: 40%;
  padding-left: 15px;
  float: right;
}
#current .right .right-bg{
  background-color: #f5f5f5;
  padding: 10px;
}
#current .add-remove{
  width: 100%;
  margin-bottom: 5px;
  display: inline-block;
}
#current .add-remove .left-sec{
  width: 50%;
  float: left;
}
#current .add-remove .right-sec{
  width: 50%;
  float: right;
  text-align: right;
}
#current .add-remove span{
  padding-right:10px;
  border-right:solid 1px #999;
}
#current .add-remove a{
  margin-left: 10px;
  font-size: 13px;
}
#current .add-remove a:hover{
  text-decoration: none;
}
#current .right table{
  width: 100%;
}
#current .right table tr{
  border-bottom: solid 1px #ddd;
}
#current .right table tr th,#current .right table tr td{
  padding: 10px;
}
#current .right table .minus,#current .right table .plus{
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 18px;
  font-size: 20px;
  border-radius: 30px;
  display: inline-block;
  border: solid 1px #999;
  margin-right: 10px;
  cursor: pointer;
}
#current .right table .plus{
  margin-left: 10px;
}
#current .right table .edit{
  cursor: pointer;
}
#current .right .bulk-discount{
  width: 100%;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 20px;
}
#current .right .bulk-discount a{
  display: inline-block;
  color: #66afe9;
}
#current .right .bulk-discount a:hover{
  text-decoration: underline;
}
#current .btn-grp{
  width: 100%;
  display: block;
  overflow: hidden;
}
#current .btn-grp button.btn{
  border-radius: 0px;
  width: 50%;
  float: left;
  padding: 10px 0px;
  font-weight: 600;
  font-size: 16px;
}
#current .btn-grp button.btn span{
  font-weight: 300;
  font-size: 14px;
  margin-left: 10px;
}
.edit {
  cursor: pointer;
}
/*************PopUP CSS*********/
.popup{
	/* width:700px; */
	background:#fff;
	/* box-shadow:0px 0px 10px rgba(0,0,0,0.3); */
	margin:auto;
}
.popup .heading{
	padding:15px 20px;
	display:block;
	overflow:hidden;
	border-bottom:solid 1px #e5e5e5;
}
.popup .heading h5{
	display:inline-block;
	float:left;
	margin:0px;
	color:#222222;
	font-weight:400;
}
.popup .heading svg{
	float:right;
	margin-top:5px;
	color:#a6a6a6;
}
.popup .quantity{
	padding:25px 20px 0px;
	display:block;
	overflow:hidden;
}
.popup .quantity label{
	display:block;
}
.popup .quantity input{
	width:100%;
	padding:5px 10px;
	border:solid 1px #e5e5e5;
}
.popup .quantity .minus button, .popup .quantity .plus button{
	width:30px;
	height:30px;
	line-height:27px;
	border-radius:50%;
	border:solid 2px #e5e5e5;
	color:#222222;
	display:inline-block;
	float:left;
	text-align:center;
	margin:15px 5px;
}
.popup .price{
	padding:15px 20px 30px;
	display:block;
	overflow:hidden;
	/* border-bottom:solid 1px #e5e5e5; */
}
.popup .price h6{
	font-weight:400;
	margin-bottom:15px;
}
.popup .price .radio-section label{
	margin-right:15px;
	width:auto;
}
.popup .price .radio-section input{
	width:auto;
}
.popup .price input{
	width:100%;
	padding:5px 10px;
	border:solid 1px #e5e5e5;
	margin:10px 0px 20px;
}
.popup .price label{
	width:100%;
}
.popup .price textarea{
	width:100%;
	padding:5px 10px;
}
.popup .btn-group{
	padding:20px;
	float:right;
}
.popup .btn-group button{
	margin:0px 5px;
	padding:7px 15px;
	border:solid 1px #cccccc;
	background-color:#e7e7e7;
	color:#222222;
}
.popup .btn-group button:hover{
	background-color:#dddddd;
}
.popup .btn-group button.save{
	background-color:#43ac6a;
	border-color:#43ac6a;
	color:#fff;
}
.popup .btn-group button.save:hover{
	background-color:#3c995f;
}


.left-dashboard{
  width: 20%;
  min-height: 100vh;
  height: auto;
  background-color: #31373d;
  display:inline-block;
  float: left;
}
.left-dashboard.success{
  display: none;
}
/* .left-dashboard.danger {
  display: inline-block;
} */
.left-dashboard ul{
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}
.left-dashboard ul li{
  width:100%;
  position: relative;
}
.left-dashboard ul li:before{
  content: "";
  position: absolute;
  left:10px;
  top:21px;
  background-color: #d3d3d3;
  width: 7px;
  height:7px;
  border-radius: 50%;
}
.left-dashboard ul li a{
  color: #d3d3d3 !important;
  font-weight: 500;
  padding: 12px 20px 12px 25px;
  width: 100%;
  display: block;
}
.left-dashboard ul li a:hover,.left-dashboard ul li a.active{
  text-decoration: none;
  background-color: #454e57;
  color: #fff;
}
.left-dashboard ul li ul{
  padding-left: 25px;
}
.left-dashboard ul li ul li{
  position: relative;
}
.left-dashboard ul li ul li:before{
  content: "";
  position: absolute;
  left:10px;
  top:23px;
  background-color: #d3d3d3;
  width: 7px;
  height:2px;
}
.left-dashboard ul.btm-ul{
  margin-top: 10px;
  padding-top: 10px;
  border-top: dashed 2px #d3d3d3;
}

#sell{
  width:80%;
  float: right;
}
#sell.danger{
  width: 100%;
}
#logo{
  cursor: pointer;
}
.p-t-10{
  padding-top: 10px;
}
.p-t-20{
  padding-top: 10px;
}
.p-l-5{
  padding-left: 5px !important;
}

.p-datatable .p-datatable-thead > tr > th{
  padding: 6px!important;
  color: #000!important;
}
.p-component{
  font-size: 14px!important;
}
.p-datatable .p-datatable-tbody > tr > td{
  padding: 6px!important;
}

.p-datatable .p-datatable-tbody > tr{
  color: #000!important;
}
.p-radiobutton .p-radiobutton-box,.p-radiobutton{
  height: 16px!important;
  width: 16px!important;
}
.p-dialog .p-dialog-header{
  padding: 15px!important;
}
.p-dialog .p-dialog-footer{
  padding: ;
}
.p-dialog .p-dialog-content{
  padding:0 15px 2rem 15px!important;
}
.p-button{
  border:0px!important;
}
.p-button.p-confirm-dialog-accept{
  background: linear-gradient(to right,#ff3834 0,#ff7133 100%)!important;
  color: #fff;
}
.p-button.p-confirm-dialog-reject{
  background: #B9B9B9;
  color: #000;
}

.p-button.p-confirm-dialog-reject:enabled:hover{
  background: #B9B9B9!important;
  color: #000!important;
}
button:focus{
  outline: 0px;
}
.p-button:focus{
  box-shadow: none!important;
}

